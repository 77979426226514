<template>
  <v-menu bottom left offset-y tile transition="slide-y-transition" min-width="234">
    <template #activator="{ on }">
      <v-btn text v-on="on" right dark>
        <span class="mr-4">{{ $t('global.greet', [username]) }}</span>
        <v-btn fab small color="primary">
          <v-icon>$profile</v-icon>
        </v-btn>
      </v-btn>
    </template>
    <v-list dark color="secondary" class="text-subtitle-2">
      <v-list-item @click="handleLogout">
        <v-list-item-icon>
          <v-icon>$signOff</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          {{ $t('authorisation.actions.logOff').toUpperCase() }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AppBarMenu',
  computed: {
    ...mapGetters('authorisation', ['username'])
  },
  methods: {
    ...mapActions({
      logout: 'authorisation/logout',
    }),
    handleLogout() {
      this.logout();
      this.$router.push({ name: 'login' });
    },
  },
};
</script>
