<template>
  <v-list-item active-class="activeClass" :to="route" :value="$route.name === route" exact>
    <v-list-item-action class="pl-2" v-if="icon">
      <v-icon size="24">{{ icon }}</v-icon>
    </v-list-item-action>
    <v-list-item-title>
      <v-list-item-title>
        <strong>{{ title.toUpperCase() }}</strong>
      </v-list-item-title>
    </v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: 'KListTitle',
  props: {
    icon: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    route: {
      type: Object,
    },
  },
};
</script>

<style scoped>
.activeClass {
  color:            #FFF;
  background-color: #000;
  border-radius:    0;
}
</style>
